@import "./assets/fonts/fonts.css";

* {
  box-sizing: border-box;
}

html,
body,
#root {
  font-family: 'FFDINforPUMA-Bold', 'FFDINforPUMA-Regular';
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  max-height: 700px;
}

#root {
  background: transparent;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  max-height: 700px;
  background: #000000;  
}

@media only screen and (max-width: 768px) {
  html,
  body,
  #root {
    max-height: 100%;
  }
}

.wrapper-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

video {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  z-index: 1;
}

canvas {
  position: relative;
  z-index: 2;
}
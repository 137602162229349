@font-face {
    font-family: "FFDINforPUMA-Bold";
    src: url("FFDINforPUMA-Bold.woff2") format("woff2"), url("FFDINforPUMA-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FFDINforPUMA-Regular";
    src: url("FFDINforPUMA-Regular.woff2") format("woff2"), url("FFDINforPUMA-Regular.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FFDINforPUMA-Light";
    src: url("FFDINforPUMA-Light.woff2") format("woff2"), url("FFDINforPUMA-Light.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}